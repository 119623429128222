

































import Vue from "vue";
import Login from "../components/Login.vue";
import * as Axios from "axios";

export default Vue.extend({
  name: "Home",
  components: {
      login: Login
  },
  methods: {
      doSomething: function() {
          this.loginDialogVisible = true;
      },
      makeApiCall: async function(): Promise<any> {
          const axios = Axios.default;
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
          axios.defaults.headers.common['X-Api-Key'] = 'qvF0aU7lbL8d5vFZvnyxK7n06cDQWXm43K8p6epg';
          axios.defaults.headers.common['Content-Type'] = 'application/json';
          axios.defaults.headers.common['Authorization'] = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdXRoVHlwZSI6InVzZXIiLCJleHBpcmVzIjoxNjExMzUyMjA5Nzc2LCJ1c2VySWQiOiIrMTQ4MDk4MDM5NDkifQ.WlFcnxL51ZvDAwVQ5fV_nZBlIYZ7ydvZQsZHgJt86iw';

          const instance = axios.create({
                baseURL: 'https://qlmx0179ig.execute-api.us-east-1.amazonaws.com/dev/',
                timeout: 10000
            });

        // let response = await instance.post("/auth/phone", {phone_number: "+1 (480) 980-3949", validation_code: "123456"});
        let response = await instance.get("/user");
        this.currentStatus = response.data.phone_number;
      }
  },
  data: () => ({
      loginDialogVisible: false,
      currentStatus: "This is the current status."
  })
});
