<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      persistent
      max-width="80%"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template> -->
      <v-card>
        <v-card-title class="headline">
          Login
        </v-card-title>
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="close()"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="close()"
          >
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

  export default {
    props: {
        isVisible: {
            default: false
        }
    },
    methods: {
        close() {
            this.$emit('update:isVisible', false)
        }
    }
  }

</script>
