<template>
  <v-app>    
        <!-- <v-navigation-drawer app>
            
        </v-navigation-drawer> -->

        <v-app-bar app>
            <!-- -->
            <router-link to="/">Home</router-link>
            <v-spacer></v-spacer>
            <router-link to="/about">About</router-link>
        </v-app-bar>

        <v-main>
            <v-container fluid>
                <router-view> 
                    <!-- -->
                </router-view>
            </v-container>
        </v-main>

        <v-footer app>
            <v-col
                class="text-center"
                cols="12"
            >
            Copyright &copy; {{ new Date().getFullYear() }} — <strong>BluRaven</strong>
            </v-col>
        </v-footer>
  </v-app>
</template>

<script>

export default {
  name: "App",

  components: {
  },

  data: () => ({
    //
  })
};
</script>
